import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
// import styles from '../css/aboutus.module.css';
import PageHeading from '../../components/PageHeading/PageHeading';
import TitleBar from '../../components/TitleBar/TitleBar';
// import TableauViz from '../../components/TableauViz/TableauViz';
import LoadableTableau from '../../components/TableauViz/LoadableTableau';

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = { code: '' };
  }

  componentDidMount = () => {
    this.setState({ code: window.location.href });
  };

  render() {
    // let baseUrl = '';
    // if (typeof window !== `undefined`) {
    //   baseUrl = encodeURIComponent(window.location.origin);
    // }
    const baseUrl = encodeURIComponent('https://veym.net');

    return (
      <Layout>
        <Helmet
          title="VEYM | Membership"
          meta={[
            {
              name: 'description',
              content: `Instructions for official membership`,
            },
          ]}
        />
        <PageHeading>Membership</PageHeading>

        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Membership</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <TitleBar>Membership Registration</TitleBar>
        <br />
        <Container>
          <p>
            We are in the process of migrating our membership system. If you
            need your Member ID, please follow the instructions below:
          </p>
          <ol>
            <li>
              Go to the{' '}
              <a
                href="https://members.veym.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                Members Portal
              </a>{' '}
              and log in. If you do not have an account, please create one and
              sign in with any of the available options.
            </li>
            <li>
              To register for a VEYM account (@veym.net), please go to{' '}
              <a
                href="https://signup.veym.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                signup.veym.net
              </a>
              .
            </li>
            <li>
              If you have any issues, please email{' '}
              <a href="mailto:support@veym.net">support@veym.net</a>.
            </li>

            {/* <li>
              <a
                href={`https://login.microsoftonline.com/b5c47154-d563-428d-9f1b-3216e2435d14/oauth2/v2.0/authorize?client_id=729f8d03-7e9f-457a-8852-18bf3fe51239&scope=openid%20profile%20user.read&response_type=code&redirect_uri=${baseUrl}%2Fstewardship%2Fmembership`}
              >
                Click here
              </a>{' '}
              to log in. Please enter your VEYM credentials.
            </li>
            <li>
              Your Member ID will be shown to you below. If your Member ID is
              not shown below, please submit a ticket at{' '}
              <a
                href="https://veym.freshdesk.com/support/tickets/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                support.veym.net
              </a>
            </li> */}
          </ol>

          <br />
          {this.state.code.includes('?code') ? (
            <iframe
              src={`https://memberinfo.veym.net/?url=${this.state.code}`}
              width="100%"
              frameBorder="0"
              title="memberInfo"
            />
          ) : null}
          <br />
          <br />
        </Container>
        <TitleBar>Membership Dues</TitleBar>
        <br />
        <Container>
          <p>
            Over the last several years, it is without a doubt that the
            Vietnamese Eucharistic Youth Movement (VEYM) has grown at an
            unprecedent pace with a record high of 140 Chapters nationwide and
            growing. In reaching its historic 35th anniversary, the VEYM is
            recognized as the modeled youth ministry across the country in the
            promotion of faith, discipleship and family. To continue to stay
            steadfast on course amidst the many challenges facing the youth
            today, we need resources. As such, now more than ever, membership
            dues are increasingly vital.
          </p>
          <p>
            In accordance with the Bylaws 2019 (Chapter VIII, Clause 63), making
            contribution to the VEYM via membership dues is required and is the
            responsibility of all members. It is also the responsibility of the
            National Executive Committee (Ban Chấp Hành Trung Ương) and the
            Finance Committee (Ban Tài Chánh) to present and make available the
            VEYM’s annual{' '}
            <a
              href="https://wp.veym.net/wp-content/uploads/2019/10/2018-Financial-Report.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              financial report
            </a>{' '}
            to all for transparency and accountability (report was presented to
            League of Chapters Presidents in May, 2019, and published in TNTT
            Bi-Weekly).
          </p>
          <p>
            With unanimous consensus and support from League of Chapters
            Presidents (Liên Đoàn Trưởng) during recent meetings with the
            National Board of Executive Officers (Ban Thường Vụ Trung Ương) in
            April and May, 2019, the National Executive Committee (Ban Chấp Hành
            Trung Ương){' '}
            <b>
              will begin to hold accountable all Chapters with regards to
              membership dues following the deadline date to submit membership
              dues, which is November 30th, 2019.
            </b>
          </p>
          <p>
            Chapters not in compliance with membership dues as outlined by the
            Bylaws will not be eligible to receive VEYM’s services (e.g.,
            training resources, Weekly Gospels, promotions and processing,
            scholarships, campaign initiatives, training camps, IT support and
            services, online Education Center, registration to Conventions,
            Congresses, and etc...).{' '}
            <b>
              Chapters with unique challenges in meeting the membership dues
              requirements are invited to contact the National Executive
              Committee at headquarters@veym.net for guidance and support.
            </b>
          </p>
        </Container>
        <br />
        <TitleBar>Submitting Membership Dues</TitleBar>
        <br />
        <Container>
          <p>
            To submit Chapter's membership dues, please complete the following
            steps:
          </p>
          <ol>
            <li>
              Go to{' '}
              <a
                href="https://membership.veym.net/membership-dues"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://membership.veym.net/membership-dues
              </a>
              .
            </li>
            <li>
              Select corresponding "League of Chapters" and "Chapter"; click
              "ADD TO CART".
            </li>
            <li>
              For each membership category listed, enter the number of members
              registered at your Chapter.
            </li>
            <li>Click "ADD TO CART" for each membership category.</li>
            <li>
              {' '}
              Once completed, click “Shopping Cart” at top right; click "LOGIN
              TO CHECKOUT".
            </li>
            <li>
              {' '}
              Sign in with your VEYM account. If you do not have one, register
              at{' '}
              <a
                href="https://signup.veym.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://signup.veym.net
              </a>
              .
            </li>
            <li>Once redirected to the Shopping Cart; click "CHECKOUT".</li>
            <li>
              Select billing address or enter a new one; select payment method.
              Either pay with credit card on site (will incur a 2% fee) or
              request a quote to pay with a check (quote may be used to provide
              to parish if needed).
            </li>
            <li>
              Confirm the information is accurate and proceed to confirm
              payment. If requesting a quote, please follow the instructions on
              the screen carefully.
            </li>
            <li>
              Email confirmation will be available once the order process is
              completed.
            </li>
          </ol>
          <hr />
          <p>
            To submit membership dues ($5/member) to League of Chapters, please
            contact your League of Chapters Executive Committee directly (Ban
            Chấp Hành Liên Đoàn).
          </p>
        </Container>
        <br />
        <TitleBar>Membership Census 2019-2020</TitleBar>
        <br />
        <Container>
          {typeof window !== undefined && <LoadableTableau />}
        </Container>
        <br />
        <br />
        <TitleBar>VEYM IT Support</TitleBar>
        <br />
        <Container>
          <p>
            For questions regarding VEYM IT services, please visit the support
            site at{' '}
            <a
              href="https://support.veym.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              support.veym.net
            </a>
            . If you cannot find an answer in the knowledge base, submit a
            ticket and someone from the IT Support Team will help you.
          </p>
        </Container>
        <br />
      </Layout>
    );
  }
}

export default Membership;

export const frontmatter = {
  title: 'Membership',
  category: 'pages',
  path: '/stewardship/membership',
  description: 'Membership Information',
  tags: 'membership, nien liem, register, registration, member ID, support,  ',
};
