import React from 'react';
import Loadable from 'react-loadable';
import { Spinner } from 'reactstrap';

const LoadableTableau = () => {
  const LoadableComponent = Loadable({
    loader: () => import('./TableauViz'),
    loading() {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spinner color="secondary" size="lg" />;
        </div>
      );
    },
  });

  return <LoadableComponent />;
};

export default LoadableTableau;
